const SocialMediaLinks = {
    FACEBOOK_LINK:"https://www.facebook.com/profile.php?id=61559215366808&mibextid=ZbWKwL",
    INSTAGRAM_LINK:"https://www.instagram.com/getfreepani/",
    YOUTUBE_LINK:"",
    LINKEDIN_LINK:"https://www.linkedin.com/company/getfreepani/",
    TWITTER_LINK:"",
    WHATS_APP_LINK:"https://wa.me/+918210347394?text=Hey!%20I%20visited%20your%20website.%20Can%20I%20get%20more%20information.",
    DRASTICK_LINK:"https://drastick.in"
}





export default SocialMediaLinks;