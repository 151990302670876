import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getFirestore, collection, getDocs } from "firebase/firestore";

const GetQueriesData = ({ isAuthenticated, user }) => {
    const [contacts, setContacts] = useState([]);
    const [loading, setLoading] = useState(true);

    const navigate = useNavigate();

    useEffect(() => {
        if (!isAuthenticated) {
            navigate('/login');
        }
    }, [isAuthenticated, navigate]);

    useEffect(() => {
        const fetchData = async () => {
            const db = getFirestore();
            const data = await getDocs(collection(db, "contact_us"));
            const contacts = data.docs.map(doc => ({ ...doc.data(), id: doc.id }));
            contacts.sort((a, b) => {
                const aDate = new Date(a.date);
                const bDate = new Date(b.date);
                return bDate - aDate;
            });
            setContacts(contacts);
            setLoading(false);
        };

        fetchData();
    }, []);

    if (loading) {
        return <div className="flex justify-center items-center h-screen"><h1 className="text-2xl font-bold mb-4">Loading Data.......</h1></div>;
    }
    return (
        <div className=''>
            {user && (
                <div className='text-right text-gray-600 mb-4'>
                    <h2>Welcome, <span className='font-bold'>{user.name}</span> !</h2>
                    {/* display other user details */}
                </div>
            )}
            <div className=" mx-auto p-8 bg-white shadow-md rounded-md">
                    <h1 className="text-2xl font-bold mb-4">Queries List</h1>
                    <p className="text-gray-600 mb-4">
                        List of Queries from the users. 
                    </p>

            <table className="min-w-full divide-y divide-gray-200">
                <thead className="bg-gray-50">
                    <tr>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Sl No.
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Name
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Email
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Message
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Phone No
                        </th>
                        <th scope="col" className="px-6 py-3 text-left text-xs font-medium text-gray-500 uppercase tracking-wider">
                            Date
                        </th>
                    </tr>
                </thead>
                <tbody className="bg-white divide-y divide-gray-200">
                    {contacts.map((contact,index) => (
                        <tr key={contact.id} className="hover:bg-gray-200">
                            <td className="px-6 py-4 whitespace-nowrap  text-left">
                                <div className="text-sm text-gray-900">{index+1}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap  text-left">
                                <div className="text-sm text-gray-900">{contact.name}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap  text-left">
                                <div className="text-sm text-gray-900">{contact.email}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap  text-left">
                                <div className="text-sm text-gray-900">{contact.message}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap  text-left">
                                <div className="text-sm text-gray-900">{contact.phone}</div>
                            </td>
                            <td className="px-6 py-4 whitespace-nowrap  text-left">
                                <div className="text-sm text-gray-900">{contact.date}</div>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>

            <p className="text-gray-600 mb-4 text-left">Total Rows: {contacts.length}</p>
            </div>
        </div>
    );
};

export default GetQueriesData;