import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, } from 'react-router-dom';
import Home from './Pages/Home';
import Advertise from './Pages/Advertise';
import Distribute from './Pages/Distribute';
import FAQ from './Pages/FAQ';
import './App.css';
import Header from './Components/Header';
import Footer from './Components/Footer';
import ContactUs from './Pages/ContactUs';
import LoginPage from './Pages/LoginPage';
import GetQueriesData from './Pages/GetQueriesData';
import { getFirestore, collection, query, where, getDocs } from 'firebase/firestore';


function App() {

  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [user, setUser] = useState(null);

  const handleLogin = async (username, password) => {
    try {
      const db = getFirestore();
      const usersCollection = collection(db, 'users');
      const q = query(usersCollection, where('username', '==', username), where('password', '==', password));
      const querySnapshot = await getDocs(q);

      if (querySnapshot.empty) {
        // User not found or invalid credentials
        console.log('User not found or invalid credentials');
        alert('Invalid credentials');
        setIsAuthenticated(false);
        localStorage.removeItem('loginStatus');
        setUser(null);
        return false;
      } else {
        console.log('User found and authenticated successfully');
        setIsAuthenticated(true);
        setUser(querySnapshot.docs[0].data());
        localStorage.setItem('loginStatus', JSON.stringify({ time: Date.now(), user: querySnapshot.docs[0].data() }));
        return true;
      }
    } catch (error) {
      console.error('Error authenticating user:', error);
      setIsAuthenticated(false);
      return false;
    }
  };

  const handleLogout = () => {
    setIsAuthenticated(false);
    setUser(null);
    localStorage.removeItem('loginStatus');
  };
  return (
    <div className="App">

      <Router>
        <Header onLogout={handleLogout} isAuthenticated={isAuthenticated} />
        <Routes>
          <Route path="/login" element={<LoginPage onLogin={handleLogin} setIsAuthenticated={setIsAuthenticated} setUser={setUser} />} />
          <Route path="/" element={<Home />} />
          <Route path="/advertise" element={<Advertise />} />
          <Route path="/distribute" element={<Distribute />} />
          <Route path="/faq" element={<FAQ />} />
          <Route path="/contact" element={<ContactUs />} />
          {/* <Route path="/queries" element={<GetQueriesData />} /> */}
          <Route path="/queries" element={<GetQueriesData isAuthenticated={isAuthenticated} user={user} />} />
          {/* Add more routes as needed */}
        </Routes>
        <Footer />
      </Router>

    </div>
  );
}

export default App;
