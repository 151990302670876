import React from 'react';
import userM from '../assets/images/userM.jpeg';
import userF from '../assets/images/userF.jpeg';

const Testimonial = () => {
  return (
    <div>
      <section class="text-gray-600 body-font">

        <div class="container px-5 py-24 mx-auto">
          <div class="flex flex-col text-center w-full mb-20">
            {/* <h2 class="text-xs text-indigo-500 tracking-widest font-medium title-font mb-1">GET FREE PANI</h2> */}
            <h1 class="sm:text-3xl text-2xl font-medium title-font text-gray-900">Testimonial</h1>
          </div>
          <div class="flex flex-wrap -m-4">
            <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
              <div class="h-full text-center">
                <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={userM} />
                <p class="leading-relaxed">I was skeptical about advertising on water bottles at first, but GetFreePani's service exceeded my expectations. Not only did I reach a wide audience, but the team's support throughout the process made it hassle-free and effective</p>
                <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
                <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">Rahul Murmu</h2>
                {/* <p class="text-gray-500">Senior Product Designer</p> */}
              </div>
            </div>
            <div class="lg:w-1/3 lg:mb-0 mb-6 p-4">
              <div class="h-full text-center">
                <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={userF} />
                <p class="leading-relaxed">As a small business owner, I'm always looking for innovative ways to promote my products. GetFreePani's platform provided me with a unique opportunity to showcase my brand on their premium water bottles, and the results were fantastic. Highly recommended!</p>
                <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
                <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">Ayushi</h2>
                {/* <p class="text-gray-500">UI Develeoper</p> */}
              </div>
            </div>
            <div class="lg:w-1/3 lg:mb-0 p-4">
              <div class="h-full text-center">
                <img alt="testimonial" class="w-20 h-20 mb-8 object-cover object-center rounded-full inline-block border-2 border-gray-200 bg-gray-100" src={userM} />
                <p class="leading-relaxed">I've been using GetFreePani's advertising service for my skincare line, and I've been blown away by the response. The sleek design of the bottles coupled with the targeted audience reach has significantly boosted my brand awareness. Thank you, GetFreePani, for helping my business grow!</p>
                <span class="inline-block h-1 w-10 rounded bg-indigo-500 mt-6 mb-4"></span>
                <h2 class="text-gray-900 font-medium title-font tracking-wider text-sm">Sandeep Mandal</h2>
                {/* <p class="text-gray-500">CTO</p> */}
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Testimonial;