// import React from 'react';
import React, { useState } from 'react';

const FAQ = () => {
    const faqData = [

        {
          question: "How is GetFreePaani Free?",
          answer: "GetFreePaani is paid for by ads that are printed directly onto Plastic bottles."
        },
        {
          question: "How can I help GetFreePaani?",
          answer: "Please help us to spread the word by sharing our project on social media and with your friends and family."
        },
        {
          question: "How much does it cost to advertise?",
          answer: "It depends on several factors:",
          ol:["Plastic bottles 250ml, 500ml & 1000ml?",
          "The number of units?",
          "Are we distributing the water or are you distributing the water?",
          "The location of distribution?",
          "Do you have a graphic designer on staff or do you need us to design it for you?",
          "Are you splitting the ad space with someone else?",


          ],
          notes:"Plastic bottles have a MOQ of a pallet which has 500 units. You can split the label’s real estate with a total of two brands..."

        },
        {
          question: "How long does it take to place an advertising order?",
          answer: "Lead time for plastic bottle is 1 weeks."
        },
        {
          question: "How does GetFreePaani compare to other advertising mediums?",
          answer: "GetFreePaani is the best brand loyalty platform on Earth and there are unique opportunities to incorporate this new and exciting medium into most ad budgets."
        },
        {
          question: "How do I advertise?",
          answer: "To advertise with GetFreePaani, please visit https://getfreepaani.in"
        }
      ]      

    const [activeIndex, setActiveIndex] = useState(null);

    const toggleAccordion = (index) => {
        setActiveIndex(index === activeIndex ? null : index);
    };

    return (
        <div>
         
            <h1 className="text-2xl font-bold mb-4">Frequently Asked Questions</h1>
            <div className="mx-12">
            {faqData.map((item, index) => (
                <div
                    key={index}
                    className="border-b border-gray-200 py-4"
                >
                    <button
                        className="flex items-center justify-between w-full focus:outline-none"
                        onClick={() => toggleAccordion(index)}
                    >
                        <span className="text-lg text-leftw font-medium">{item.question}</span>
                        <svg
                            className={`w-5 h-5 transition-transform ${
                                index === activeIndex ? 'transform rotate-180' : ''
                            }`}
                            viewBox="0 0 24 24"
                            fill="none"
                            stroke="currentColor"
                            strokeWidth="2"
                            strokeLinecap="round"
                            strokeLinejoin="round"
                        >
                            <path d="M19 9l-7 7-7-7" />
                        </svg>
                    </button>
                    {index === activeIndex && (
                        <div className="mt-4 text-left">
                            <p>{item.answer}</p>
                            {/* if it has ol */}
                            {item.ol && (
                              <ol style={{listStyle:'decimalt'}} className="ml-6 text-left">
                                {item.ol.map(listItem => <li>{listItem}</li>)}
                              </ol>
                            )}
                            {/* if it has Notes */}
                              <br />
                            {item.notes && 
                            <strong>{item.notes}</strong>
                            }
                        </div>
                    )}
                </div>
            ))}
        </div>
        </div>
    );
};

export default FAQ;