import React, { useEffect } from 'react';

const Parallax = (props) => {
    console.log("props", props);
    useEffect(() => {
        const parallax = document.querySelector('.parallax');
        window.addEventListener('scroll', () => {
            let offset = window.pageYOffset;
            parallax.style.backgroundPositionY = offset * 0.7 + 'px';
        });
    }, []);

    return (
        <div className="parallax" style={{ backgroundImage: `url(${props.image})`, backgroundSize: 'cover' }}>

            <div className='py-12 px-12 text-right'>
                <h1 className="sm:text-3xl text-2xl font-medium title-font text-gray-900">{props.heading}</h1>
                <br />

                <p class="leading-relaxed">
                    {props.paragraph.split('.').map((item, key) => {
                        return <span key={key}>{item}.<br /></span>
                    })
                    }

                </p>
            </div>

        </div>
    );
};

export default Parallax;