import React from 'react';
import Hero from '../Components/Hero';
import Statistics from '../Components/Statistics';
import Testimonial from '../Components/Testimonial';
import Steps from '../Components/Steps';
import Feature from '../Components/Feature';
import Contact from '../Components/Contact';
import Parallax from '../Components/Parallex';
import userM from '../assets/images/parallax.jpg';

const Home = () => {
    return (
        <div>
            <Hero />
            <Feature />

            <Steps />
            {/* <Statistics /> */}
            {/* //paralex */}
            <Parallax image={userM} heading="Welcome to Get Free Pani!" paragraph="At Get Free Pani, we believe that access to clean water is a fundamental human right.
                    Unfortunately, many people around the world still lack access to safe drinking water.
                    Our mission is to change that by providing innovative solutions to
                    ensure everyone has access to clean, safe, and free water. " />
            <Testimonial />
            {/* <Contact /> */}
            {/* <p>This is a sample JSX component.</p> */}
        </div>
    );
};

export default Home;