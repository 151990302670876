// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
const firebaseConfig = {
  apiKey: "AIzaSyDFQMaAJpY7P41Mahp7NDK6xzPnv0_9k9M",
  authDomain: "getfreepani.firebaseapp.com",
  projectId: "getfreepani",
  storageBucket: "getfreepani.appspot.com",
  messagingSenderId: "171635331795",
  appId: "1:171635331795:web:24013b98f92fd55898ba26"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);