import React, { useState } from 'react';
import '../firebaseConfig.js'; // Add this line prevent firebase not loading error
import { getFirestore, addDoc, collection, getDocs } from "firebase/firestore";

const ContactUs = () => {
    const [name, setName] = useState('');
    const [email, setEmail] = useState('');
    const [phone, setPhone] = useState('');
    const [message, setMessage] = useState('');

    const [nameError, setNameError] = useState('');
    const [emailError, setEmailError] = useState('');
    const [phoneError, setPhoneError] = useState('');
    const [messageError, setMessageError] = useState('');
    

    const db = getFirestore();


    const handleSubmit = async (e) => {
        e.preventDefault();

        // Validation starts here

        setNameError('');
        setEmailError('');
        setPhoneError('');
        setMessageError('');

        if (!name) {
            setNameError('Name is required.');
            return;
        }

        const emailRegex = /^[\w-]+(\.[\w-]+)*@([\w-]+\.)+[a-zA-Z]{2,7}$/;
        if (!email || !emailRegex.test(email)) {
            setEmailError('Please enter a valid email.');
            return;
        }

        const phoneRegex = /^[0-9]{10}$/;
        if (!phone || !phoneRegex.test(phone)) {
            setPhoneError('Please enter a valid phone number.');
            return;
        }

        if (!message) {
            setMessageError('Message is required.');
            return;
        }

        // Validation ends here

        const docRef = await addDoc(collection(db, "contact_us"), {
            name: name,
            email: email,
            phone: phone,
            message: message,
            date: new Date().toLocaleString()
        });
        alert("We have sent the Notification to the Admin. We will get back to you soon. Thank you for your patience.");

        setName('');
        setEmail('');
        setPhone('');
        setMessage('');
        window.location.href = "/";
    };

    return (
        <div>
            <div className="flex justify-center items-center h-screen bg-gray-100">
                <div className="max-w-2xl mx-auto p-8 bg-white shadow-md rounded-md">
                    <h1 className="text-2xl font-bold mb-4">Advertise with Us</h1>
                    <p className="text-gray-600 mb-4">Enhance your brand’s visibility and tap into the advertising industry’s most effective brand platform for increased engagement and impact.</p>

                    <form className="mb-4 mx-12" onSubmit={handleSubmit}>
                        <div className="mb-4">
                            <label htmlFor="name" className="block text-gray-700 font-bold mb-2 text-left">Name</label>
                            <input type="text" id="name" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500" value={name} onChange={(e) => setName(e.target.value)} />
                            {nameError && <p className="text-left text-red-500">{nameError}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="email" className="block text-gray-700 font-bold mb-2 text-left">Email</label>
                            <input type="email" id="email" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500" value={email} onChange={(e) => setEmail(e.target.value)} />
                            {emailError && <p className="text-left text-red-500">{emailError}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="phone" className="block text-gray-700 font-bold mb-2 text-left">Phone Number</label>
                            <input type="text" id="phone" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500" value={phone} onChange={(e) => setPhone(e.target.value)} />
                            {phoneError && <p className="text-left text-red-500">{phoneError}</p>}
                        </div>
                        <div className="mb-4">
                            <label htmlFor="message" className="block text-gray-700 font-bold mb-2 text-left">Message</label>
                            <textarea id="message" className="w-full px-3 py-2 border border-gray-300 rounded-md focus:outline-none focus:ring focus:ring-blue-500" value={message} onChange={(e) => setMessage(e.target.value)}></textarea>
                            {messageError && <p className="text-left text-red-500">{messageError}</p>}
                        </div>
                        <button type="submit" className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600">Submit</button>
                    </form>
                </div>
            </div>
        </div>

    );
};

export default ContactUs;
