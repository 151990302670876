import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import icon from '../assets/images/GetFreePaniLogo1.png';

const Header = ({ onLogout, isAuthenticated }) => {

  const navigate = useNavigate();
  return (
    <div>
      <header class="text-gray-600 body-font">
        <div class="container mx-auto flex flex-wrap p-5 flex-col md:flex-row items-center">
          <Link to="/" class="flex title-font font-medium items-center text-gray-900 mb-4 md:mb-0">
            {/* <svg xmlns="http://www.w3.org/2000/svg" fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-10 h-10 text-white p-2 bg-blue-500 rounded-full" viewBox="0 0 24 24">
              <path d="M12 2L2 7l10 5 10-5-10-5zM2 17l10 5 10-5M2 12l10 5 10-5"></path>
            </svg> */}
            <img src={icon} alt="GetFreePani" class="w-10 h-10" />
            <span class="ml-3 text-xl">GetFreePani</span>
          </Link>
          {/* <Link to="/" class="mr-5 hover:text-gray-900">Home</Link> */}
          <nav class="md:mr-auto md:ml-4 md:py-1 md:pl-4 md:border-l md:border-gray-400	flex flex-wrap items-center text-base justify-center">
            {/* <a class="mr-5 hover:text-gray-900">Home</a>
            <a class="mr-5 hover:text-gray-900">Advertise</a>
            <a class="mr-5 hover:text-gray-900">Distribute</a>
            <a class="mr-5 hover:text-gray-900">FAQ</a>
            <a class="mr-5 hover:text-gray-900">Contact Us</a> */}
            <Link to="/" class="mr-5 hover:text-gray-900">Home</Link>
            <Link to="/advertise" class="mr-5 hover:text-gray-900">Advertise</Link>
            {/* <Link to="/distribute" class="mr-5 hover:text-gray-900">Distribute</Link> */}
            <Link to="/faq" class="mr-5 hover:text-gray-900">FAQ</Link>
            <Link to="/contact" class="mr-5 hover:text-gray-900">Contact Us</Link>
          </nav>
          
          {isAuthenticated && (
                <button onClick={onLogout} class="inline-flex items-center bg-gray-100 border-0 py-1 px-3 focus:outline-none hover:bg-gray-200 rounded text-base mt-4 md:mt-0">Logout
                <svg fill="none" stroke="currentColor" stroke-linecap="round" stroke-linejoin="round" stroke-width="2" class="w-4 h-4 ml-1" viewBox="0 0 24 24">
                  <path d="M5 12h14M12 5l7 7-7 7"></path>
                </svg>
              </button>
            )}
            
          {/* {!isAuthenticated && !window.location.href.includes('login') && (
                <button onClick={()=>navigate('/login')} class="inline-flex items-center text-white bg-blue-500 border-0 py-1 px-3 focus:outline-none hover:bg-blue-700 rounded text-base mt-4 md:mt-0">Login
              </button>
            )} */}
        </div>
      </header>
    </div>
  );
};

export default Header;